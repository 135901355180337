
































import Vue from "vue";
import { VerifyEmail } from "@/interfaces/auth/verify_email";
import SubmitButton from "@/components/auth/SubmitButton.vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_USER_DETAILS,
  VERIFY_EMAIL_ANAUTHORIZED
} from "@/store/modules/auth/constants";
import router from "@/router";
export default Vue.extend({
  name: "EmailVerifyUnauthorized",
  components: { SubmitButton },
  data(): VerifyEmail & { email: string } {
    return {
      decoration_img_src: require("@/assets/illustrations/email.svg"),
      verification_code: "",
      is_valid: false,
      email: ""
    };
  },
  async created() {
    const code = atob(this.$route.params.code).split("_&_");

    if (code) {
      this.verification_code = code[1];
      this.email = code[0];
      await this.submit_form();
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  watch: {
    verification_code(new_value: string) {
      this.is_valid = /^[0-9]{4}$/.test(new_value);
    }
  },
  methods: {
    ...mapActions("auth", {
      verify_email: VERIFY_EMAIL_ANAUTHORIZED
    }),
    async submit_form() {
      if (this.verification_code && this.verification_code.length === 4) {
        const response = await this.verify_email({
          token: this.verification_code,
          email: this.email
        });
        if (response) {
          await router.push({
            name: "Email Verified"
          });
          this.verification_code = "";
          this.is_valid = false;
        }
      }
    }
  }
});
